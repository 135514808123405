import styled from '@emotion/styled';
import PostList from '../components/Post/PostList';

const HomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HomeHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const Home = () => {
  return (
    <HomeContainer>
      <HomeHeader>
        <Title>欢迎来到我的博客</Title>
        <Subtitle>分享技术，记录生活</Subtitle>
      </HomeHeader>
      <section>
        <h2>最新文章</h2>
        <PostList />
      </section>
    </HomeContainer>
  );
};

export default Home; 