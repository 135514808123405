import styled from '@emotion/styled';

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const AboutHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
`;

const Section = styled.section`
  margin-bottom: 2rem;
  color: ${props => props.theme.text};
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const About = () => {
  return (
    <AboutContainer>
      <AboutHeader>
        <Title>关于我</Title>
        <Subtitle>热爱技术，热爱生活</Subtitle>
      </AboutHeader>

      <Section>
        <SectionTitle>个人简介</SectionTitle>
        <p>
          你好！我是一名全栈开发工程师，热衷于Web开发和新技术探索。
          这个博客是我用来分享技术心得、学习笔记和生活感悟的地方。
        </p>
      </Section>

      <Section>
        <SectionTitle>技术栈</SectionTitle>
        <ul>
          <li>前端：React, Vue, TypeScript</li>
          <li>后端：Node.js, Python</li>
          <li>数据库：MongoDB, MySQL</li>
          <li>其他：Docker, Git</li>
        </ul>
      </Section>

      <Section>
        <SectionTitle>联系方式</SectionTitle>
        <p>
          Email: example@email.com<br />
          GitHub: github.com/yourusername
        </p>
      </Section>
    </AboutContainer>
  );
};

export default About; 