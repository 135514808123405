import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import CommentSection from '../components/Comment/CommentSection';
import TableOfContents from '../components/Post/TableOfContents';
import { posts } from '../data/posts';

const PostLayout = styled.div`
  display: grid;
  grid-template-columns: 250px minmax(auto, 800px) 1fr;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const PostContainer = styled.article`
  background: ${props => props.theme.cardBg};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${props => props.theme.shadowColor};
  padding: 2rem;
`;

const PostHeader = styled.header`
  margin-bottom: 2rem;
  text-align: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${props => props.theme.border};
`;

const PostTitle = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
`;

const PostMeta = styled.div`
  color: ${props => props.theme.secondary};
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const PostContent = styled.div`
  line-height: 1.8;
  color: ${props => props.theme.text};
  
  h2 {
    font-size: 1.8rem;
    margin: 2.5rem 0 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.border};
  }

  h3 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
  }

  h4 {
    font-size: 1.2rem;
    margin: 1.5rem 0 1rem;
  }

  p {
    margin-bottom: 1.5rem;
    text-align: justify;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 2rem auto;
    display: block;
    border-radius: 8px;
  }

  pre {
    background: ${props => props.theme.cardBg};
    padding: 1.5rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 1.5rem 0;
  }

  blockquote {
    border-left: 4px solid ${props => props.theme.primary};
    padding: 1rem 1.5rem;
    margin: 1.5rem 0;
    background: ${props => props.theme.cardBg};
    border-radius: 0 8px 8px 0;
    color: ${props => props.theme.secondary};
  }

  ul, ol {
    margin: 1rem 0 1rem 2rem;
    
    li {
      margin-bottom: 0.5rem;
    }
  }
`;

const TOCWrapper = styled.div`
  position: sticky;
  top: 2rem;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  padding-right: 1rem;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const PostDetail = () => {
  const { id } = useParams();
  
  // 从posts数据中查找对应的文章
  const post = posts.find(p => p.id === parseInt(id)) || {
    title: '文章未找到',
    date: '',
    author: '',
    content: '抱歉，未找到该文章',
    tags: []
  };

  return (
    <PostLayout>
      <TOCWrapper>
        <TableOfContents content={post.content} />
      </TOCWrapper>
      <PostContainer>
        <PostHeader>
          <PostTitle>{post.title}</PostTitle>
          <PostMeta>
            <span>作者：{post.author}</span>
            <span>发布于：{post.date}</span>
            <span>
              标签：
              {post.tags.join(', ')}
            </span>
          </PostMeta>
        </PostHeader>
        <PostContent dangerouslySetInnerHTML={{ __html: post.content }} />
        <CommentSection postId={id} />
      </PostContainer>
      <div /> {/* 右侧空白区域 */}
    </PostLayout>
  );
};

export default PostDetail; 