export const posts = [
  {
    id: 1,
    title: 'React 18新特性介绍',
    date: '2024-03-20',
    author: '张三',
    excerpt: 'React 18带来了许多激动人心的新特性，让我们一起来看看...',
    tags: ['React', 'JavaScript'],
    content: `
      <h2 id="intro">简介</h2>
      <p>React 18是一个重要的版本更新，带来了许多重要的新特性和改进。本文将详细介绍这些变化，以及如何在项目中使用它们。</p>
      
      <h2 id="features">主要特性</h2>
      <p>React 18引入了多个新的特性，包括：</p>
      <ul>
        <li>自动批处理</li>
        <li>并发特性</li>
        <li>Suspense改进</li>
      </ul>
      
      <h3 id="auto-batching">自动批处理</h3>
      <p>自动批处理是React 18中一个重要的性能优化特性...</p>
      
      <h3 id="concurrent">并发特性</h3>
      <p>并发渲染是React 18的核心新特性之一...</p>
      
      <h2 id="migration">迁移指南</h2>
      <p>从React 17迁移到React 18需要注意以下几点：</p>
      
      <h3 id="breaking-changes">破坏性变更</h3>
      <p>以下是一些需要注意的破坏性变更...</p>
      
      <h2 id="conclusion">总结</h2>
      <p>React 18的更新为我们带来了更好的性能和开发体验...</p>
    `
  },
  {
    id: 2,
    title: 'CSS Grid布局完全指南',
    date: '2024-03-19',
    author: '李四',
    excerpt: '详细介绍CSS Grid布局的使用方法和最佳实践...',
    tags: ['CSS', 'Web开发'],
    content: `
      <h2 id="grid-basics">Grid布局基础</h2>
      <p>CSS Grid是一个强大的二维布局系统，让我们从基础概念开始...</p>
      
      <h3 id="grid-container">网格容器</h3>
      <p>要创建网格布局，首先需要设置网格容器...</p>
      
      <h3 id="grid-items">网格项目</h3>
      <p>网格项目是网格容器的直接子元素...</p>
      
      <h2 id="grid-properties">Grid属性详解</h2>
      <p>Grid布局提供了丰富的属性来控制布局...</p>
      
      <h3 id="grid-template">模板属性</h3>
      <p>grid-template-columns和grid-template-rows是最基础的Grid属性...</p>
    `
  }
]; 