import styled from '@emotion/styled';

const FooterContainer = styled.footer`
  padding: 2rem;
  text-align: center;
  background: ${props => props.theme.cardBg};
  border-top: 1px solid ${props => props.theme.border};
  margin-top: 4rem;
`;

const BeianContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const BeianTitle = styled.span`
  color: ${props => props.theme.secondary};
  font-size: 0.9rem;
`;

const BeianLink = styled.a`
  color: ${props => props.theme.secondary};
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <BeianContainer>
        <BeianTitle>备案号：</BeianTitle>
        <BeianLink 
          href="https://beian.miit.gov.cn/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          皖ICP备2024057016号
        </BeianLink>
      </BeianContainer>
    </FooterContainer>
  );
};

export default Footer; 