import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { darkTheme, lightTheme } from './styles/theme';
import { globalStyles } from './styles/GlobalStyles';
import App from './App';

// 包装组件来连接 Context 和 Emotion 的主题
const ThemeProviderWrapper = ({ children }) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </EmotionThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ThemeProviderWrapper>
        <App />
      </ThemeProviderWrapper>
    </ThemeProvider>
  </React.StrictMode>
); 