import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ThemeToggle from '../Common/ThemeToggle';
import SearchBar from '../Common/SearchBar';
import { useAuth } from '../../context/AuthContext';

const HeaderContainer = styled.header`
  padding: 1rem 2rem;
  background-color: ${props => props.theme.headerBg};
  box-shadow: 0 2px 4px ${props => props.theme.shadowColor};
`;

const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;

  a {
    color: ${props => props.theme.text};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.text};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const AuthButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const AuthButton = styled(Link)`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
  
  ${props => props.primary ? `
    background: ${props.theme.primary};
    color: white;
    
    &:hover {
      opacity: 0.9;
    }
  ` : `
    color: ${props.theme.text};
    
    &:hover {
      color: ${props.theme.primary};
    }
  `}
`;

const Header = () => {
  const { user, logout, isAuthenticated } = useAuth();

  return (
    <HeaderContainer>
      <Nav>
        <Logo to="/">我的博客</Logo>
        <NavRight>
          <SearchBar />
          <NavLinks>
            <Link to="/posts">文章</Link>
            <Link to="/about">关于</Link>
          </NavLinks>
          {isAuthenticated ? (
            <AuthButtons>
              <span>欢迎，{user.username}</span>
              <AuthButton as="button" onClick={logout}>退出</AuthButton>
            </AuthButtons>
          ) : (
            <AuthButtons>
              <AuthButton to="/login">登录</AuthButton>
              <AuthButton to="/register" primary>注册</AuthButton>
            </AuthButtons>
          )}
          <ThemeToggle />
        </NavRight>
      </Nav>
    </HeaderContainer>
  );
};

export default Header; 