import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import PostList from '../components/Post/PostList';

const SearchContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const SearchHeader = styled.div`
  margin-bottom: 2rem;
`;

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  return (
    <SearchContainer>
      <SearchHeader>
        <h1>搜索结果：{query}</h1>
      </SearchHeader>
      <PostList />
    </SearchContainer>
  );
};

export default SearchResults; 