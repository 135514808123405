import styled from '@emotion/styled';
import PostList from '../components/Post/PostList';
import { posts } from '../data/posts';

const PostsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const PostsHeader = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: ${props => props.theme.secondary};
  font-size: 1.1rem;
`;

const Posts = () => {
  return (
    <PostsContainer>
      <PostsHeader>
        <Title>所有文章</Title>
        <Description>分享技术与生活的点点滴滴</Description>
      </PostsHeader>
      <PostList posts={posts} />
    </PostsContainer>
  );
};

export default Posts; 