import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import PostList from '../components/Post/PostList';

const TagContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const TagHeader = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const TagTitle = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;

const TagDescription = styled.p`
  color: #666;
`;

const TagPage = () => {
  const { tag } = useParams();

  return (
    <TagContainer>
      <TagHeader>
        <TagTitle>标签：{tag}</TagTitle>
        <TagDescription>
          查看所有与 "{tag}" 相关的文章
        </TagDescription>
      </TagHeader>
      <PostList />
    </TagContainer>
  );
};

export default TagPage; 