import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

const TOCContainer = styled.nav`
  background: ${props => props.theme.cardBg};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${props => props.theme.shadowColor};
  padding: 1.5rem;
`;

const TOCTitle = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.border};
  text-align: center;
  font-size: 1.5rem;
`;

const TOCList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TOCItem = styled.li`
  margin: 0.5rem 0;
  padding-left: ${props => props.level * 1.5}rem;
  
  a {
    color: ${props => props.active ? props.theme.primary : props.theme.text};
    text-decoration: none;
    font-size: 0.9rem;
    display: block;
    padding: 0.3rem 0;
    transition: all 0.2s ease;
    border-left: 2px solid ${props => 
      props.active ? props.theme.primary : 'transparent'};
    padding-left: 1rem;
    
    &:hover {
      color: ${props => props.theme.primary};
      background: ${props => props.theme.shadowColor};
      border-radius: 0 4px 4px 0;
    }
  }
`;

const TableOfContents = ({ content }) => {
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    // 解析文章内容中的标题
    const elements = document.querySelectorAll('h2, h3, h4');
    const headingsList = Array.from(elements).map(heading => ({
      id: heading.id,
      text: heading.innerText,
      level: parseInt(heading.tagName.charAt(1)) - 1
    }));
    setHeadings(headingsList);

    // 监听滚动以更新活动标题
    const handleScroll = () => {
      for (const heading of headingsList.reverse()) {
        const element = document.getElementById(heading.id);
        if (element && element.getBoundingClientRect().top <= 100) {
          setActiveId(heading.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [content]);

  return (
    <TOCContainer>
      <TOCTitle>目录</TOCTitle>
      <TOCList>
        {headings.map(heading => (
          <TOCItem 
            key={heading.id} 
            level={heading.level}
            active={activeId === heading.id}
          >
            <a href={`#${heading.id}`}>{heading.text}</a>
          </TOCItem>
        ))}
      </TOCList>
    </TOCContainer>
  );
};

export default TableOfContents; 