export const lightTheme = {
  background: '#ffffff',
  text: '#333333',
  primary: '#007bff',
  secondary: '#6c757d',
  border: '#dee2e6',
  cardBg: '#ffffff',
  headerBg: '#ffffff',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  navText: '#333333'
};

export const darkTheme = {
  background: '#1a1a1a',
  text: '#e0e0e0',
  primary: '#4dabf7',
  secondary: '#adb5bd',
  border: '#404040',
  cardBg: '#2d2d2d',
  headerBg: '#252525',
  shadowColor: 'rgba(0, 0, 0, 0.3)',
  navText: '#ffffff'
}; 