import styled from '@emotion/styled';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CommentContainer = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
`;

const CommentForm = styled.form`
  margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  min-height: 100px;
`;

const Button = styled.button`
  padding: 0.5rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CommentItem = styled.div`
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
`;

const CommentTitle = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.border};
  text-align: center;
  font-size: 1.5rem;
`;

const LoginPrompt = styled.div`
  text-align: center;
  padding: 2rem;
  background: ${props => props.theme.cardBg};
  border-radius: 8px;
  margin-bottom: 2rem;

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
    margin-left: 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CommentSection = ({ postId }) => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [comments, setComments] = useState([
    { id: 1, author: '访客', content: '很棒的文章！', date: '2024-03-20' }
  ]);
  const [newComment, setNewComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    if (!newComment.trim()) return;

    const comment = {
      id: comments.length + 1,
      author: user.username,
      content: newComment,
      date: new Date().toLocaleDateString()
    };

    setComments([...comments, comment]);
    setNewComment('');
  };

  return (
    <CommentContainer>
      <CommentTitle>评论</CommentTitle>
      {isAuthenticated ? (
        <CommentForm onSubmit={handleSubmit}>
          <TextArea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="写下你的评论..."
          />
          <Button type="submit">发表评论</Button>
        </CommentForm>
      ) : (
        <LoginPrompt>
          请先登录后发表评论
          <Link to="/login">去登录</Link>
        </LoginPrompt>
      )}
      <CommentList>
        {comments.map(comment => (
          <CommentItem key={comment.id}>
            <CommentHeader>
              <span>{comment.author}</span>
              <span>{comment.date}</span>
            </CommentHeader>
            <p>{comment.content}</p>
          </CommentItem>
        ))}
      </CommentList>
    </CommentContainer>
  );
};

export default CommentSection; 