import styled from '@emotion/styled';
import { useTheme } from '../../context/ThemeContext';

const ToggleButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: ${props => props.theme.text};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const ThemeToggle = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <ToggleButton onClick={toggleTheme} aria-label="切换主题">
      {isDarkMode ? '🌞' : '🌙'}
    </ToggleButton>
  );
};

export default ThemeToggle; 