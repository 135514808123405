import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Pagination from '../Common/Pagination';

const PostContainer = styled.div`
  margin: 2rem 0;
`;

const PostCard = styled.article`
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-3px);
  }
`;

const PostTitle = styled.h3`
  margin: 0 0 1rem 0;
  color: #333;
`;

const PostMeta = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
`;

const PostExcerpt = styled.p`
  color: #666;
  line-height: 1.6;
`;

const PostList = ({ posts = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;
  
  // 计算当前页的文章
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  return (
    <>
      <PostContainer>
        {currentPosts.map(post => (
          <PostCard key={post.id}>
            <PostTitle>
              <Link to={`/post/${post.id}`}>{post.title}</Link>
            </PostTitle>
            <PostMeta>
              发布于 {post.date} | 标签：
              {post.tags.map((tag, index) => (
                <span key={tag}>
                  {index > 0 && ', '}
                  <Link to={`/tag/${tag}`}>{tag}</Link>
                </span>
              ))}
            </PostMeta>
            <PostExcerpt>{post.excerpt}</PostExcerpt>
          </PostCard>
        ))}
      </PostContainer>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </>
  );
};

export default PostList; 